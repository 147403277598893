/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useRef, useState } from "react";
import { graphql } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";
import Button from "~components/Button";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const ContactPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  const submitRef = useRef();

  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [name, setName] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  //

  const cms = data.sanityContactPage;

  //

  const submitProxy = e => {
    e.preventDefault();

    if (submitRef?.current) {
      submitRef.current.click();
    }

    return false;
  };

  const onSubmit = e => {
    e.preventDefault();

    if (submitting || submitted) {
      return false;
    }

    setSubmitting(true);

    //

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/email`, {
      body: JSON.stringify({
        name,
        email,
        message
      }),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then(() => {
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });

    return false;
  };

  //

  return (
    <>
      <SEO customTitle={cms.title} path={location.pathname} />

      <Layout className="contact-page w-full relative pt-v12 xs:pt-v20 pb-v6 xs:pb-24">
        <section className="grid xs:pt-v12">
          <article className="grid-end-5 xs:grid-end-12">
            <h1 className="f3">{cms.title}</h1>
            <div
              className="mt-v2 xs:mt-v8 xs:mb-v9 whitespace-pre-wrap b1"
              dangerouslySetInnerHTML={{ __html: cms.content }}
            ></div>
          </article>

          <form
            className={`${
              submitting || submitted ? `opacity-50 pointer-events-none` : ``
            } grid-end-6 xs:grid-end-12 grid-start-7 xs:grid-start-1`}
            onSubmit={onSubmit}
          >
            <input
              ref={submitRef}
              className="w-0 h-0 absolute opacity-0 pointer-events-none hidden"
              type="submit"
            />

            <input
              className={`w-full h-16 relative block mt-v1 xs:mt-v3 border-black rounded-edge--more ${
                device === `desktop` ? `b1` : `b2`
              } uppercase`}
              onChange={e => setName(e.currentTarget.value)}
              placeholder="Name*"
              required
              type="text"
            />

            <input
              className={`w-full h-16 relative block mt-v1 xs:mt-v3 border-black rounded-edge--more ${
                device === `desktop` ? `b1` : `b2`
              } uppercase`}
              onChange={e => setEmail(e.currentTarget.value)}
              placeholder="Email*"
              required
              type="text"
            />

            <textarea
              className={`w-full h-64 relative block mt-v1 xs:mt-v3 pt-v1 border-black rounded-edge ${
                device === `desktop` ? `b1` : `b2`
              } uppercase`}
              onChange={e => setMessage(e.currentTarget.value)}
              placeholder="Message*"
              required
            />

            <Button
              className="w-1/3 xs:w-full mt-v1 xs:mt-v3"
              color="black"
              transparent
              onClick={submitProxy}
            >
              {(!submitting && !submitted && (
                <span className="f-button">Submit</span>
              )) || (
                <>
                  {submitting && <span className="f-button">Sending...</span>}
                  {submitted && <span className="f-button">Thanks!</span>}
                </>
              )}
            </Button>
          </form>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage {
    sanityContactPage {
      title
      content
    }
  }
`;
